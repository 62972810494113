console.log('base js module active');
const $imgC1 = $('#img_C1');

$imgC1.on('mouseenter', () => {
    const $img = $('#c1-hover-image');
    $img.toggleClass('behidden', false);
    $img.animate({opacity: 1, top: '50px'}, 400);
  }).on('mouseleave', () => {
    $img = $('#c1-hover-image');
    $img.animate({opacity: 0, top: '-100px'},
      400, () => {$img.toggleClass('behidden', true);});
  });

const bks = ['i', 'w', 'g', 's'];
for (let i=0; i<4; i++) {
  let bk = bks[i];
  let Img = $(`#img_C2${bk}`);
  console.log(`Img ${bk}`, Img);
  Img.on('mouseenter', () => {
    const $img = $(`#c2-bk${i+1}-hover-image`);
    console.log('$img', $img);
    $img.toggleClass('behidden', false);
    $img.animate({opacity: 1}, 400);
  }).on('mouseleave', () => {
    const $img = $(`#c2-bk${i+1}-hover-image`);
    console.log('bk' + (i+1) + 'hover img', $img);
    $img.animate({opacity: 0}, 400,
      () => {$img.toggleClass('behidden', true);});
  });
}
